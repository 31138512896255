// used to prevent misspellings and scale changes to app wide history.push('xxxxx')
export const APP_ROUTES = {
	LOGOUT: '/account/logout',
	LOGIN: '/account/login'
};


export const CUSTOMER_ROUTES = {
	ROOT: '/customers',
	DASHBOARD: '/customers/customer-dashboard',
	DETAILS: '/customers/customer-details',
	NOTES: '/customers/customer-notes',
	AGENTS: '/customers/customer-agents',
	BUILDINGS: '/customers/customer-buildings',
	DEVICES: '/customers/customer-devices',
	NEW: '/customers/customer-new',
	INSPECTIONS: '/customers/customer-inspections',
	ANALYTICS: '/customers/customer-analytics',
	CERT_MANAGEMENT: '/customers/customer-cert-management',
};


export const BUILDING_ROUTES = {
	ROOT: '/buildings',
	DASHBOARD: '/buildings/building-dashboard',
	DETAILS: '/buildings/building-details',
	NOTES: '/buildings/building-notes',
	TRANSFER: '/buildings/transfer-buildings',
	DEVICES: '/buildings/building-devices',
	NEW: '/buildings/transfer-new',
};

export const ADMIN_ROUTES = {
	ROOT: '/admin',
	DASHBOARD: '/admin/admin-dashboard',
	PROPOSAL_DASHBOARD: '/admin/proposals/proposal-dashboard',
	PROPOSAL_PROPOSALS: '/admin/proposals/proposals', // list of proposals in batch
	PROPOSAL_DETAILS: '/admin/proposals/proposal-details', // list of proposals in batch
	USER_DASHBOARD: '/admin/users/user-dashboard',
	USER_SEARCH: '/admin/users/user-search',
	USER_DETAILS: '/admin/users/user-details',
	OFFICE_DASHBOARD: '/admin/offices/office-dashboard',
	OFFICE_DETAILS: '/admin/offices/details/office-details',

	// new proposal
	PROPOSAL_DASHBOARD_V2: '/admin/proposals/v2/proposal-dashboard-v2',
	PROPOSAL_PROPOSALS_V2: '/admin/proposals/v2/proposals-v2',
	PROPOSAL_DETAILS_V2: '/admin/proposals/v2/proposal-details-v2',
};



export const AGENT_ROUTES = {
	ROOT: '/agents',
	DASHBOARD: '/agents/agent-dashboard',
	DETAILS: '/agents/agent-details',
	BUILDINGS: '/agents/agent-buildings',
	TRANSFER: '/agents/transfer-agent',
	// NOTES: '/customers/customer-notes',
	// AGENTS: '/customers/customer-agents',
};

export const DEVICE_ROUTES = {
	ROOT: '/devices',
	DASHBOARD: '/devices/device-dashboard',
	DETAILS: '/devices/device-details',
	// NOTES: '/customers/customer-notes',
	// AGENTS: '/customers/customer-agents',
};


export const ELEVATOR_ROUTES = {
	ROOT: '/elevatorCompanies',
	DASHBOARD: '/elevatorCompanies/elevator-dashboard',
	DETAILS: '/elevatorCompanies/elevator-details',
	CONTACTS: '/elevatorCompanies/elevator-contacts',
	// NOTES: '/customers/customer-notes',
	// AGENTS: '/customers/customer-agents',
};

export const INSPECTION_ROUTES = {
	ROOT: '/inspections',
	DASHBOARD: '/inspections/insp-dashboard',
	SCHEDULER: '/inspections/insp-scheduler',
	SEARCH: '/inspections/insp-search',
	REPORTS: '/inspections/insp-reports',
	INVOICE: '/inspections/insp-invoice',
	INVOICE_LIST: '/inspections/insp-invoice-list',
	NEW: '/inspections/insp-new',
	DETAILS: '/inspections/insp-details',
	LINE_DETAILS: '/inspections/insp-line-details',
	UPLOADS: '/inspections/insp-uploads',

	INSPECTION_WITNESS: '/inspections/dashboard/inspection-witness',

	EMAIL: '/inspections/details/insp-email',
	CANCEL: '/inspections/details/insp-cancel'
};