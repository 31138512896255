import { BUILDING as ActionTypes } from '../actionTypes';
import { buildingInit } from '../../services/buildingApi';

const initialState = {
	building: buildingInit,
	buildings: [],
	searchText: '',
	dropdownAddressList: [],
	dropdownCorpNameList: [],
};


export const buildingReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.VERIFY_BUILDING:

			return {
				...state,
				buildings: action.payload
			};
		case ActionTypes.SET_BUILDING_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload
			};
		case ActionTypes.CLEAR_BUILDING_LIST:

			return {
				...state,
				buildings: action.payload
			};
		case ActionTypes.SET_BUILDING_LIST:

			return {
				...state,
				buildings: action.payload
			};
		case ActionTypes.SET_BUILDING:

			return {
				...state,
				building: action.payload
			};

		case ActionTypes.CLEAR_BUILDING:

			return {
				...state,
				building: buildingInit
			};
		case ActionTypes.SET_BUILDING_DD_ADDRESS_LIST:

			return {
				...state,
				dropdownAddressList: action.payload
			};

		case ActionTypes.SET_BUILDING_DD_CORP_NAME_LIST:

			return {
				...state,
				dropdownCorpNameList: action.payload
			};
		default:
			return state;
	};
};