import { CUSTOMER as ActionTypes } from '../actionTypes';
import { customerInit } from '../../services/customerApi';
import { CUSTOMER_ANALYTICS_TITLE, YEARS } from '../../appConsts';

const initialState = {
	customer: customerInit,
	customers: [],
	searchText: '',
	canInsert: false,
	dropdownCustomerNameList: [],
	inspections: [],
	analytics: {
		view: CUSTOMER_ANALYTICS_TITLE.INSPECTION_SUMMARY.TITLE,
		year: YEARS[0].value
	}
};


export const customerReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_INSPECTION_LIST:

			return {
				...state,
				inspections: action.payload
			};
		case ActionTypes.SET_CUSTOMER_DD_NAME_LIST:

			return {
				...state,
				dropdownCustomerNameList: action.payload
			};
		case ActionTypes.VERIFY_CUSTOMER:

			return {
				...state,
				customers: action.payload
			};
		case ActionTypes.SET_CUSTOMER_SEARCH_TEXT:

			return {
				...state,
				searchText: action.payload
			};
		case ActionTypes.CLEAR_CUSTOMER_LIST:

			return {
				...state,
				customers: action.payload
			};
		case ActionTypes.SET_CUSTOMER_LIST:

			return {
				...state,
				customers: action.payload
			};
		case ActionTypes.SET_CUSTOMER:

			return {
				...state,
				customer: action.payload,
				analytics: initialState.analytics,
				inspections: [] // we are loading a new customer or are getting a db reload so clear anyways
			};
		case ActionTypes.CLEAR_CUSTOMER:

			return {
				...state,
				customer: customerInit
			};
		case ActionTypes.GET_CUSTOMER:

			return {
				...state,
				customer: action.payload
			};
		case ActionTypes.SET_CUSTOMER_ANALYTICS_VIEW:

			return {
				...state,
				analytics: action.payload
			};
		default:
			return state;
	};
};