import { BOROUGH as ActionTypes } from '../actionTypes';

const initialState = {
    dropdownBoroughNameList: [],
};

export const boroughReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_BOROUGH_DD_NAME_LIST:
            return {
                ...state,
                dropdownBoroughNameList: action.payload
            };

        default:
            return state;
    };
};