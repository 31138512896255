import { AGENT as ActionTypes } from '../actionTypes';
import { agentInit } from '../../services/agentApi';

const initialState = {
	agent: agentInit,
	agents: [],
	searchText: '',
	canInsert: false
};


export const agentReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.VERIFY_AGENT:

			return {
				...state,
				agents: action.payload.list,
				canInsert: action.payload.canInsert
			};
		case ActionTypes.SET_AGENT_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload
			};
		case ActionTypes.CLEAR_AGENT_LIST:

			return {
				...state,
				agents: action.payload
			};
		case ActionTypes.SET_AGENT_LIST:

			return {
				...state,
				agents: action.payload
			};
		case ActionTypes.SET_AGENT:
			return {
				...state,
				agent: action.payload
			};
		case ActionTypes.CLEAR_AGENT:
			return {
				...state,
				agent: agentInit
			};
		default:
			return state;
	};
};