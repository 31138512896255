import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/ElevatorContact';

const elevatorContactInit = {
    'elevatorContactId': 0,
    'elevatorCompanyId': 0,
    'fullName': '',
    'license': '',

    'elevatorCompanyName': '',
    'status': '',
    'statusId': 1,

    'phone1': '',
    'phone2': '',
    'phone3': '',
    'phoneExtension': '',
    'phoneFormatted': '',
    'phone10Digits': '',

    'phone21': '',
    'phone22': '',
    'phone23': '',
    'phoneExtension2': '',
    'phoneFormatted2': '',
    'phone10Digits2': '',

    'mobile1': '',
    'mobile2': '',
    'mobile3': '',
    'mobileFormatted': '',
    'mobile10Digits': '',

    'fax1': '',
    'fax2': '',
    'fax3': '',
    'faxFormatted': '',
    'fax10Digits': '',

    'email': '',
    'isCatDirector': 0,
    'isPeriodicDirector': 0,
    'isInspector': 0,
    'isScheduling': 0,
    'isAoc': 0,
    'isForms': 0,
    'isOther': 0,
};

export { elevatorContactInit };



export const getByCompanyId = (id) => {

    let url = `${apiRoot}/GetAllByCompanyId/${id}`;

    const options = {
        method: API_METHOD.GET
    };

    try {
        return handleFetch(url, options, true);
    } catch (e) {
        debugger;
    }
};

export const getInspectors = (id) => {

    let url = `${apiRoot}/GetInspectors/${id}`;

    const options = {
        method: API_METHOD.GET
    };

    try {
        return handleFetch(url, options, true);
    } catch (e) {
        debugger;
    }
};

export const getContactsWithEmail = (id) => {

    let url = `${apiRoot}/GetContactsWithEmail/${id}`;

    const options = {
        method: API_METHOD.GET
    };

    try {
        return handleFetch(url, options, true);
    } catch (e) {
        debugger;
    }
};


export const update = (data) => {
    let url = `${apiRoot}/Update`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const insert = (data) => {
    let url = `${apiRoot}/Insert`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

// export const verifyElevator = (name) => {
//     let url = `${apiRoot}/verify`;

//     let payload = {
//         elevatorCompanyName: name
//     };

//     const options = {
//         method: API_METHOD.POST,
//         body: JSON.stringify(payload)
//     };
//     return handleFetch(url, options, true);
// };