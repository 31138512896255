import { USER as ActionTypes } from '../actionTypes';

const initialState = {

    user: {
        userId: 0,
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        secondaryEmail: '',
        phone: '',
        mobile: '',
        home: '',
        isAdmin: false,
        isActive: false, // show up on lists but can't access system
        isInspector: false,
        hasAccess: false, // can access system
        license: '',
        avatar: '',
        offices: []
    },
    users: [],
    filter: {
        firstName: '',
        lastName: '',
        searchByOptions: [
            { value: 'all', label: 'All' },
            { value: 'id', label: 'ID' },
            { value: 'firstName', label: '<NAME>' },
            { value: 'lastName', label: '<NAME>' },
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
            { value: 'license', label: 'License' },
        ]
    }
};


export const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_USER:

            return {
                ...state,
                user: action.payload
            };
        case ActionTypes.SET_USERS:

            return {
                ...state,
                users: action.payload
            };



        default:
            return state;
    };
};