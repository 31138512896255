import { INSPECTION_LINES as ActionTypes } from '../actionTypes';
//import { agentInit } from '../../services/inspectionLineApi';

const initialState = {
    line: {
        device: {},
        cat1NAChecked: true,
        cat1SChecked: false,
        cat1UChecked: false,
        cat5NAChecked: true,
        cat5SChecked: false
    },

    defs: [],
};


export const inspectionLineReducer = (state = initialState, action) => {



    switch (action.type) {


        case ActionTypes.SET_LINE:

            return {
                ...state,
                line: action.payload
            };

        case ActionTypes.SET_DEFS_LIST:

            return {
                ...state,
                defs: action.payload
            };

        default:
            return initialState;
    };
};