import { INSPECTION_SEARCH as ActionTypes } from '../actionTypes';
import { YEARS } from '../../appConsts';

const initialState = {

    searchResults: [],
    searchText: '',
    searchFilter: {
        inspectionId: '',
        deviceId: 0,
        device: '',
        customerId: 0,
        customer: '',
        buildingId: 0,
        building: '',
        corpName: '',
        elevatorId: 0,
        elevator: '',
        inspTypeId: 1,
        inspType: 'Category & Periodic',
        year: YEARS[0].value
    },
    filterArrays: {
        inspTypeList: [
            { value: 0, label: '' },
            { value: 1, label: 'Category & Periodic' },
            { value: 2, label: 'Category Only' },
            { value: 3, label: 'Periodic Only' },
        ],
        customerList: [],
        buildingList: [],
        corpList: [],
        deviceList: [],
        elevatorList: []
    }

};


export const inspectionSearchReducer = (state = initialState, action) => {



    switch (action.type) {
        case ActionTypes.SET_SEARCH_RESULTS:

            return {
                ...state,
                searchResults: action.payload
            };
        case ActionTypes.CLEAR_SEARCH_FILTERS:

            return {
                ...state,
                searchFilter: initialState.searchFilter
            };
        case ActionTypes.SET_SEARCH_FILTERS:

            return {
                ...state,
                searchFilter: action.payload
            };
        // case ActionTypes.SET_FILTER_ARRAYS:

        //     return {
        //         ...state,
        //         filterArray: {
        //             customerList: action.payload.customers,
        //             buildingList: action.payload.buildings
        //         }
        //     };


        default:
            return state;
    };
};