// userApi.js is used to populate inspectors and other modules that need to access user information - inspections, admin module

import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/user';

const userInit = {
	id: 0,
	firstLast: '',
	lastFirst: '',
	email: '',
	username: '',
	salesforceContactId: '',
	userId: 0, // legacy int user id
	isAdmin: false,
	isInspector: false,
	isActive: false,
	createdBy: '',
	dateCreated: '',
	modifiedBy: '',
	dateModified: ''
};

export { userInit };

export const getActiveInspectors = () => {

	let url = `${apiRoot}/GetActiveInspectors`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// will return the AspNetUser domain model - not the repo
export const getAllUsers = () => {

	let url = `${apiRoot}/GetAll`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


// takes a guid
export const getSingleUser = (userId) => {

	let url = `${apiRoot}/GetSingle/${userId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// take an int - we are getting a legacy user from the user table
export const getSingleUserLegacy = (userId) => {

	let url = `${apiRoot}/GetSingleLegacy/${userId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


