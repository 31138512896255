import { LOCAL_STORAGE } from '../appConsts';

export const isAuthenticated = () => {
	// check if we have a token in local storage
	const storedToken = localStorage.getItem(LOCAL_STORAGE.TOKEN);

	if(storedToken) {
		// we do
		return true;
	}

	// we don't
	return false;

};


export const getProfileFromStorage = () => {
	// save token to local storage

	const storedProfile = localStorage.getItem(LOCAL_STORAGE.PROFILE);


	if(storedProfile) {

		return JSON.parse(storedProfile);
	}

	// we don't have a profile saved in local storage
	return null;

};

export const saveToken = (token) => {
	// save token to local storage

	localStorage.setItem(LOCAL_STORAGE.TOKEN, token);
};
export const saveProfile = (profile) => {
	// save token to local storage

	localStorage.setItem(LOCAL_STORAGE.PROFILE, profile);
};

export const clearStorage = (token) => {
	// clear all local storage
	sessionStorage.clear();
	localStorage.clear();


};