import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { appReducer } from './redux/rootReducer';

const store = createStore(appReducer, applyMiddleware(thunk))




ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
