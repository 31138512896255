import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/building';

const buildingInit = {
	"buildingId": 0,
	"customerId": 0,
	"agentId": 0,
	//"agentLastFirst": "",
	"agent": {
		"lastFirst": "",
		"email": "",
		"formattedPhone": ""
	},
	"superName": "",
	"superEmail": "",
	"superPhone1": "",
	"superPhone2": "",
	"superPhone3": "",
	"superPhone10Digits": "",
	"superPhoneFormatted": "",
	"buildingName": "",
	"aka": "",
	"address": "",
	"cityId": 0,
	"city": "",
	"stateId": 0,
	"state": "",
	"zipId": 0,
	"zip": "",
	"boroughId": 0,
	"borough": "",
	"hoodId": 0,
	"hood": "",
	"block": "",
	"lot": "",
	"statusId": 1,
	"status": "",
	"createdBy": "",
	"dateCreated": "",
	"taxExempt": 0,
	"coiReceived": 0,
	"coiExpDate": "",
	"billingStreet1": "",
	"billingStreet2": "",
	"billingCity": "",
	"billingStateId": 0,
	"billingState": "",
	"billingZip": "",
	"invoiceDeliveryMethod": "",
	"openInvoiceTotal": 0,
	"isImported": false,

};

export { buildingInit };


export const transfer = (data) => {
	let url = `${apiRoot}/Transfer`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};


export const search = (data) => {
	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};


export const getSingleFromSalesforce = (salesforceId) => {

	let url = `${apiRoot}/GetSingleFromSalesforce/${salesforceId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};

export const getAllByCustomerId = (customerId) => {
	let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};

export const getAllByAgentId = (agentId) => {
	let url = `${apiRoot}/GetAllByAgentId/${agentId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};


export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const verifyNewBuilding = (address) => {
	let url = `${apiRoot}/VerifyNewBuilding`;

	let payload = {
		address: address
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};
	return handleFetch(url, options, true);
};

export const getNotes = (id) => {

	let url = `${apiRoot}/GetNotes/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};


// get building dropdown by address
export const getDropdownByAddress = () => {

	let url = `${apiRoot}/GetDropdownByAddress`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};

// get building dropdown by address
export const getDropdownByCorpName = () => {

	let url = `${apiRoot}/GetDropdownByCorpName`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};