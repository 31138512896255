import { ELEVATOR_CONTACT as ActionTypes } from '../actionTypes';
import { elevatorContactInit } from '../../services/elevatorContactApi';

const initialState = {
    elevatorContact: elevatorContactInit,
    elevatorContacts: [],
    searchText: '',
    isNew: false
};

export const elevatorContactReducer = (state = initialState, action) => {

    switch (action.type) {
        // case ActionTypes.SET_ELEVATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         searchText: action.payload
        //     };
        case ActionTypes.CLEAR_ELEVATOR_LIST:

            return {
                ...state,
                elevatorCompanies: []
            };
        case ActionTypes.SET_ELEVATOR_CONTACT_LIST:

            return {
                ...state,
                elevatorContacts: action.payload
            };
        case ActionTypes.SET_ELEVATOR_CONTACT:

            return {
                ...state,
                elevatorContact: action.payload,
                //isNew: action.payload.isNew
            };

        default:
            return state;
    };
};