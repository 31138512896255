import { ELEVATOR as ActionTypes } from '../actionTypes';
import { elevatorInit } from '../../services/elevatorApi';

const initialState = {
    elevator: elevatorInit,
    elevatorCompanies: [],
    searchText: '',
    isNew: false,
    dropdownElevatorNameList: []
};


export const elevatorReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_ELEVATOR_DD_NAME_LIST:
            return {
                ...state,
                dropdownElevatorNameList: action.payload
            };
        case ActionTypes.SET_ELEVATOR_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            };
        case ActionTypes.CLEAR_ELEVATOR_LIST:

            return {
                ...state,
                elevatorCompanies: action.payload
            };
        case ActionTypes.SET_ELEVATOR_LIST:

            return {
                ...state,
                elevatorCompanies: action.payload
            };
        case ActionTypes.SET_ELEVATOR:

            return {
                ...state,
                elevator: action.payload.object,
                isNew: action.payload.isNew
            };

        case ActionTypes.CLEAR_ELEVATOR:

            return {
                ...state,
                elevator: elevatorInit,
                isNew: false
            };

        default:
            return state;
    };
};