import { INSPECTION_DASHBOARD as ActionTypes } from '../actionTypes';

const initialState = {
	nav: {
		tab: 'dashboard',
	},
	scheduler: {
		inspectorId: 0,
		dateFrom: '',
		dateTo: '',
		selectedDate: '',
		dateRangeArray: [], //days of week, inspection items as object
		currentInspections: [], // 
		inspectorMapPins: [],
		searchedInspections: []
	}
};


export const inspectionDashboardReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_DASHBOARD_NAV:
			return {
				...state,
				nav: action.payload
			};
		case ActionTypes.GET_DASHBOARD_NAV:
			return {
				...state,
				nav: action.payload
			};

		default:
			return state;
	};
};