import { SCHEDULER as ActionTypes } from '../actionTypes';
import { userInit } from '../../services/userApi';
import { APP_YEAR } from '../../appConsts';



const initialState = {
	year: { value: APP_YEAR.CURRENT_YEAR, label: APP_YEAR.CURRENT_YEAR },
	inspector: userInit,
	inspectors: [], // only the inspectors
	inspectorsIncludeAll: [], // this array will include the option of "All Inspectors"
	weekHeaderArray: [],
	currentMonday: '',
	selectedDay: {
		index: 0,
		date: '',
		label: 'Select Day'
	},
	showLoader: { show: false, title: '', message: '', showSpinner: false, icon: '', background: '' },
	selectedDayInspections: [],
	selectedDayInspectionsMap: [],
	selectedInspector: {
		value: 0, // set it to NONE, not all which is 10000
		label: 'Select An Inspector'
	},
	weeklyInspections: [],
	emailTo: '',
	emailBody: '',
	pendingFilter: {
		boroughId: 0,
		borough: '',
		hoodId: 0,
		hood: '',
		customerId: 0,
		customer: '',
		buildingId: 0,
		building: '',
		elevatorId: 0,
		elevator: '',
		testingId: 0,
		testing: '',
		inspTypeId: 1,
		inspType: 'Category & Periodic',
		toggleDateFilter: true
	},
	filterArrays: {
		inspTypeList: [
			{ value: 0, label: '' },
			{ value: 1, label: 'Category & Periodic' },
			{ value: 2, label: 'Category Only' },
			{ value: 3, label: 'Periodic Only' }
		],
		hoodList: [],
		boroughList: [],
		customerList: [],
		buildingList: [],
		elevatorList: []
	},
	pendingInspections: [],
	pendingInspectionsMap: [],
	navigation: {
		main: 'init', // load the no data view
		details: '' // assigned: may and my inspections, map and search

	},
	mapCenter: { lat: '40.797851305367985', lng: '-73.69851500346549', zoom: 16 },
	mapTabView: 1, // assigned 1, pending 2, details 3
	selectedInspectionId: 0, // for the building details tab 
	selectedInspectionLine: { // user selected single insp_line record to edit the date, time and inspectorId
		"address": "",
		"borough": "",
		"cat1Green": false,
		"customerName": "",
		"device": "",
		"elevatorCompany": "",
		"inspectionDate": "",
		"inspectionId": 0,
		"inspectionLineId": 0,
		"inspectionStatus": "",
		"inspectionTime": "",
		"inspectionType": "",
		"isComplete": false,
		"isPeriodic": false,
		"lastCat1DateFromLine": "",
		"lastCat1FromDevice": "",
		"lastCat5Date": "",
		"lastPeriodicFromDevice": "",
		"lastPeriodicFromLine": "",
		"latitude": '',
		"longitude": "",
		"modId": 0,
		"modStatus": "",
		"neighborhood": "",
		"periodicGreen": false,
		"testingCompany": "",
		"inspectorId": 0,
		"inspector": ""
	},
	launchEditModal: false,
};

export const scheduleReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_YEAR:

			return {
				...state,
				year: action.payload
			};
		case ActionTypes.CLEAR_SELECTED_INSP_LINE:

			// clear the selected inspection line the user wants to edit the date, time and witness
			return {
				...state,
				selectedInspectionLine: state.selectedInspectionLine
			};
		case ActionTypes.SET_INSP_LINE_MODAL:
			// modal to allow user to edit the date, time and witness
			return {
				...state,
				launchEditModal: action.payload
			};
		case ActionTypes.SET_SELECTED_INSP_LINE:

			return {
				...state,
				selectedInspectionLine: action.payload,
				launchEditModal: true
			};
		case ActionTypes.SET_SELECTED_INSP_ID:

			return {
				...state,
				selectedInspectionId: action.payload
			};
		case ActionTypes.SET_MAP_TAB:

			return {
				...state,
				mapTabView: action.payload
			};
		case ActionTypes.SET_SHOW_LOADER:
			return {
				...state,
				showLoader: action.payload
			};
		case ActionTypes.SET_MAP_CENTER:

			return {
				...state,
				mapCenter: {
					lat: action.payload.lat,
					lng: action.payload.lng,
					zoom: action.payload.zoom
				}
			};
		case ActionTypes.SET_FILTER_ARRAYS:

			return {
				...state,
				filterArrays: {
					inspTypeList: [
						{ value: 0, label: '' },
						{ value: 1, label: 'Category & Periodic' },
						{ value: 2, label: 'Category Only' },
						{ value: 3, label: 'Periodic Only' },
					],
					hoodList: action.payload.hoods,
					boroughList: action.payload.boroughs,
					customerList: action.payload.customers,
					buildingList: action.payload.buildings,
					elevatorList: action.payload.elevators,
					testingList: action.payload.elevators
				}
			};
		case ActionTypes.SET_SCHEDULE_NAV:

			return {
				...state,
				navigation: action.payload
			};
		case ActionTypes.SET_WEEKLY_INSPECTIONS:

			return {
				...state,
				weeklyInspections: action.payload.schedule,
				emailTo: action.payload.sendToEmail,
				emailBody: action.payload.emailBody
			};
		case ActionTypes.SET_PENDING_FILTER:

			return {
				...state,
				pendingFilter: action.payload
			};
		case ActionTypes.SET_PENDING_INSPECTIONS:

			return {
				...state,
				pendingInspections: action.payload.pendingInspections,
				pendingInspectionsMap: action.payload.pendingInspectionsMap,
			};
		case ActionTypes.SET_INSPECTORS:

			return {
				...state,
				inspectors: action.payload.inspectors,
				inspectorsIncludeAll: action.payload.inspectorsIncludeAll,
			};
		case ActionTypes.SET_SELECTED_INSPECTOR:

			return {
				...state,
				selectedInspector: action.payload,

			};
		case ActionTypes.SET_SELECTED_DAY:

			return {
				...state,
				selectedDay: action.payload.selectedDay,
				selectedDayInspections: action.payload.selectedDayInspections,
				selectedDayInspectionsMap: action.payload.selectedDayInspectionsMap,

			};
		case ActionTypes.SET_WEEK_HEADER_ARRAY:

			return {
				...state,
				weekHeaderArray: action.payload.headerArray,
				currentMonday: action.payload.headerArray[0],
				selectedDay: '',
				selectedDayInspections: []
			};
		default:
			return state;
	};
};