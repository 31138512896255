import { INSPECTOR as ActionTypes } from '../actionTypes';

const initialState = {
    activeInspectors: [],
    allInspectors: [],
};

export const inspectorReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_ACTIVE_INSPECTORS:
            return {
                ...state,
                activeInspectors: action.payload
            };
        case ActionTypes.SET_ALL_INSPECTORS:
            return {
                ...state,
                allInspectors: action.payload
            };
        default:
            return state;
    };
};