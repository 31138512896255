import { PROFILE as ActionTypes } from '../actionTypes';

const initialState = {
	// profile: { // has to be called selectedQuestion because we have a child property also called question,
	// 	userId: '',
	// 	firstName: '',
	// 	lastName: ''
	// },

	profile: {
		userId: 0,
		id: '',
		firstName: '',
		lastName: '',
		middleName: '',
		nickName: '',
		email: '',
		secondaryEmail: '',
		phone: '',
		mobile: '',
		home: '',
		isAdmin: false,
		isActive: false, // show up on lists but can't access system
		isInspector: false,
		hasAccess: false,		// can access system
		license: '',
		avatar: ''
	},
	roles: [],
};


export const profileReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_PROFILE:
			return {
				...state,
				profile: action.payload
			};
		case ActionTypes.CLEAR_PROFILE:

			return {
				...initialState,
			};



		default:
			return state;
	};
};