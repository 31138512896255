import { INVOICE as ActionTypes } from '../actionTypes';

const initialState = {
    selectedBatch: {
        batchId: 0,
        statusId: 0,
        status: '',
        inspectionsRemaining: 0,
        inspectionsPushed: 0,
        inspectionsCount: 0,
        inspectionsRunningTotal: '0',
        inspectionsTotal: '0'
    },

};

export const invoiceReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_BATCH:
            return {
                ...state,
                selectedBatch: action.payload
            };

        default:
            return state;
    };
};