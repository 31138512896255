import { combineReducers } from 'redux';
import { profileReducer } from './reducers/profileReducer';
import { customerReducer } from './reducers/customerReducer';
import { agentReducer } from './reducers/agentReducer';
import { buildingReducer } from './reducers/buildingReducer';
import { inspectionDashboardReducer } from './reducers/inspectionDashboardReducer';
import { scheduleReducer } from './reducers/scheduleReducer';
import { deviceReducer } from './reducers/deviceReducer';
import { elevatorReducer } from './reducers/elevatorReducer';
import { elevatorContactReducer } from './reducers/elevatorContactReducer';
import { inspectionSearchReducer } from './reducers/inspectionSearchReducer';
import { boroughReducer } from './reducers/boroughReducer';
import { hoodReducer } from './reducers/hoodReducer';
import { inspectionReducer } from './reducers/inspectionReducer';
import { inspectionLineReducer } from './reducers/inspectionLineReducer';
import { inspectorReducer } from './reducers/inspectorReducer';
import { inspectionReportReducer } from './reducers/inspectionReportReducer';
import { invoiceReducer } from './reducers/invoiceReducer';
import { userReducer } from './reducers/userReducer';
import { officeReducer } from './reducers/officeReducer';

export const appReducer = combineReducers(
	{
		profileReducer,
		customerReducer,
		agentReducer,
		buildingReducer,
		scheduleReducer,
		inspectionDashboardReducer,
		deviceReducer,
		elevatorReducer,
		elevatorContactReducer,
		inspectionSearchReducer,
		boroughReducer,
		hoodReducer,
		inspectionReducer,
		inspectionLineReducer,
		inspectorReducer,
		inspectionReportReducer,
		invoiceReducer,
		userReducer,
		officeReducer

	});