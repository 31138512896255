import { INSPECTION_REPORT as ActionTypes } from '../actionTypes';
import { YEARS } from '../../appConsts';

const initialState = {

    reportResults: [],
    searchText: '',
    reportFilter: {
        inspectionId: '',
        deviceId: 0,
        device: '',
        customerId: 0,
        customer: 'Select A Customer',
        buildingId: 0,
        building: '',
        corpName: '',
        elevatorId: 0,
        elevator: 'Select An Elevator Company',
        inspTypeId: 1,
        inspType: 'Category & Periodic',
        year: YEARS[0].value,
        dateFrom: '',
        dateTo: '',
        isAocChecked: true,
        isCertificateManagement: false,
        aocDateFiledIsBlank: true // aoc_elv29_to_city maps to this field
    },
    filterArrays: {
        inspTypeList: [
            { value: 0, label: '' },
            { value: 1, label: 'Category & Periodic' },
            { value: 2, label: 'Category Only' },
            { value: 3, label: 'Periodic Only' },
        ],
        customerList: [],
        buildingList: [],
        corpList: [],
        deviceList: [],
        elevatorList: []
    }

};


export const inspectionReportReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_REPORT_RESULTS:

            return {
                ...state,
                reportResults: action.payload
            };
        case ActionTypes.CLEAR_REPORT_FILTERS:

            return {
                ...state,
                reportFilter: initialState.reportFilter
            };
        case ActionTypes.SET_REPORT_FILTERS:

            return {
                ...state,
                reportFilter: action.payload
            };
        // case ActionTypes.SET_REPORT_FILTERS:

        //     return {
        //         ...state,
        //         filterArray: {
        //             customerList: action.payload.customers,
        //             buildingList: action.payload.buildings
        //         }
        //     };


        default:
            return state;
    };
};