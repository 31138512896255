import { HOOD as ActionTypes } from '../actionTypes';

const initialState = {
    dropdownHoodNameList: [],
};

export const hoodReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_HOOD_DD_NAME_LIST:
            return {
                ...state,
                dropdownHoodNameList: action.payload
            };

        default:
            return state;
    };
};