import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/customer';

const customerInit = {
	"customerId": 0,
	"customerName": "",
	"address": "",
	"address2": "",
	"city": "",
	"stateId": 0,
	"state": "",
	"zipId": 0,
	"zip": "",
	"phone1": "",
	"phone2": "",
	"phone3": "",
	"phoneExtension": "",
	"phoneFormatted": "",
	"phone10Digits": "",
	"fax1": "",
	"fax2": "",
	"fax3": "",
	"faxFormatted": "",
	"fax10Digits": "",
	"statusId": 0,
	"status": "",
	"email": "",
	"emailCC": "",
	"complianceContact": "",
	"webSite": "",
	"poRequired": false,
	"poRequiredDisplay": "",
	"createdBy": "",
	"dateCreated": "",
	"modifiedBy": "",
	"dateModified": "",
	"deletedBy": "",
	"dateDeleted": "",
	"salesforceId": "",
	"openInvoiceTotal": 0,
	"isCertificateManagement": false,
	"isCertificateManagementDisplay": "",
	"isImported": false
};

export { customerInit };

export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


export const getSingleFromSalesforce = (salesforceId) => {

	let url = `${apiRoot}/GetSingleFromSalesforce/${salesforceId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};

export const search = (data) => {

	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const verifyNewCustomer = (customerName) => {
	let url = `${apiRoot}/VerifyNewCustomer`;

	let payload = {
		name: customerName
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};
	return handleFetch(url, options, true);
};

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};


export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};


// get cutomer dropdown by address
export const getDropdownByCustomerName = () => {

	let url = `${apiRoot}/GetDropdownByCustomerName`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};



// for analytics view
export const getInspectionSummary = (customerId, year) => {

	let url = `${apiRoot}/GetAnalyticsInspectionSummary/${customerId}/${year}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


export const getElevatorResults = (data) => {

	let url = `${apiRoot}/GetElevatorCatResults`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};
