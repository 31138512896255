import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/ElevatorCompany';

const elevatorInit = {
    "elevatorCompanyId": 0,
    "elevatorCompanyName": "",
    "status": "",
    "statusId": 1,
    "address": "",
    "address2": "",
    "city": "",
    "state": "",
    "zip": "",
    "phone1": "",
    "phone2": "",
    "phone3": "",
    "phoneExtension": "",
    "phoneFormatted": "",
    "phone10Digits": "",
    "phone21": "",
    "phone22": "",
    "phone23": "",
    "phoneExtension2": "",
    "phoneFormatted2": "",
    "phone10Digits2": "",
    "fax1": "",
    "fax2": "",
    "fax3": "",
    "faxFormatted": "",
    "fax10Digits": "",
    "email": "",
    "webSite": ""
};

export { elevatorInit };

export const search = (data) => {
    let url = `${apiRoot}/Search`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};



export const getSingle = (id) => {

    let url = `${apiRoot}/GetSingle/${id}`;

    const options = {
        method: API_METHOD.GET
    };

    try {
        return handleFetch(url, options, true);
    } catch (e) {
        debugger;
    }
};

export const update = (data) => {
    let url = `${apiRoot}/Update`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const insert = (data) => {
    let url = `${apiRoot}/Insert`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const verifyElevator = (name) => {
    let url = `${apiRoot}/verify`;

    let payload = {
        elevatorCompanyName: name
    };

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
};



// get elevator dropdown by name
export const getDropdownByElevatorName = () => {

    let url = `${apiRoot}/GetDropdownByElevatorName`;

    const options = {
        method: API_METHOD.GET
    };

    try {
        return handleFetch(url, options, true);
    } catch (e) {

    }
};