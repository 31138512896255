import { OFFICE as ActionTypes } from '../actionTypes';

const initialState = {

    office: {
        officeId: '',
        name: '',
        statusId: 0,
        status: '',
        states: [],
        users: []
    },
    offices: [],
    filter: {
        firstName: '',
        lastName: '',
        searchByOptions: [
            { value: 'all', label: 'All' },
            { value: 'id', label: 'ID' },
            { value: 'firstName', label: '<NAME>' },
            { value: 'lastName', label: '<NAME>' },
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
            { value: 'license', label: 'License' },
        ]
    }
};

export const officeReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_OFFICE:
            return {
                ...state,
                office: action.payload
            };
        case ActionTypes.SET_OFFICES:

            return {
                ...state,
                offices: action.payload
            };



        default:
            return state;
    };
};