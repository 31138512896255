import { DEVICE as ActionTypes } from '../actionTypes';
import { deviceInit } from '../../services/deviceApi';

const initialState = {
	device: deviceInit,
	devices: [],
	searchText: '',
	canInsert: false,
	dropdownDeviceNameList: []
};


export const deviceReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_DEVICE_DD_NAME_LIST:
			return {
				...state,
				dropdownDeviceNameList: action.payload
			};
		case ActionTypes.SET_DEVICE_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload
			};
		case ActionTypes.CLEAR_DEVICE_LIST:

			return {
				...state,
				devices: action.payload
			};
		case ActionTypes.SET_DEVICE_LIST:

			return {
				...state,
				devices: action.payload
			};
		case ActionTypes.SET_DEVICE:

			return {
				...state,
				device: action.payload
			};


		case ActionTypes.CLEAR_DEVICE:

			return {
				...state,
				device: deviceInit
			};


		default:
			return state;
	};
};